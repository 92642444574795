import accountServices from '../../../services/account'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import {signIn} from '@/utils/auth.js'
import {signOut} from '@/utils/auth.js'
//import awsmobile from '../../../aws-exports'
export default {
    name: "SignIn",
    data() {
        return {
            payload: {},
            isUserConfirmed:false
        }
    },
    created(){
       console.clear();
        if (this.$route && this.$route.query.e) {
            this.payload = {    
                email: this.$route.query.e,
                code: this.$route.query.y,
                password: null,
                confirm_password: null,
            }
        } else {            
            this.$router.push({name: 'Login'})
        }
    },
    methods: {
        async ConfirmCode() {
            //console.log('Code 1');
            await Auth.confirmSignUp(this.payload.email, this.payload.code).then(data => {
                AmplifyEventBus.$emit('authState', 'signIn')
                console.clear();
                //console.log('Code 2');
                signIn(this.payload.email, 'Temp@321').then(user => {
                    console.clear();
                    //console.log('Singin');
                    debugger
                    Auth.currentAuthenticatedUser()
                    .then(user => {
                        console.clear();
                        //console.log('Auth');
                        debugger
                        //console.log('Pasword');
                        Auth.changePassword(user, 'Temp@321', this.payload.password).then(user => {
                            //console.log('Auth');
                        debugger
                        //console.log('Pasword');
                    })
                    }).catch(err => {
                        this._loadingEnd()
                        console.clear();
                        console.log('Error ' + err);
                        debugger
                    })
                    .then(data => {
                        this._loadingEnd()
                        console.clear();
                        //console.log('Verified');
                        debugger
                        signOut()
                        this.$router.push({name: 'SuccessPage', params: {
                            title: 'Account Verified!',
                            subTitle: 'You have successfully updated the password',
                            icon:'far fa-check-circle'
                        }})
                    }
                        )
                    .catch(err => {
                        this._loadingEnd()
                        console.clear();
                        console.log('Error ' + err);
                        debugger
                    });
                })
                debugger
                return data // 'SUCCESS'
              })
                .catch(err => {
                  // console.clear();
                  //console.log('Verified 1');
                    debugger
                    if(err.message == "User cannot be confirmed. Current status is CONFIRMED")
                    {
                        this._loadingEnd()
                        this._showToast("User account is already verified.", { variant: 'danger',title:'Error' })
                        this.isUserConfirmed = true
                    }
                });
        },
        // getUser() {
        //     return Auth.currentAuthenticatedUser().then((user) => {
        //        console.clear();
        //       if (user && user.signInUserSession) {
        //        console.clear();
        //         return user
        //       } else {
        //        console.clear();
        //         return null
        //       }
        //     }).catch(err => {
        //        console.clear();
        //     });
        //   },
          async  UpdatePassword() {
            if(!this.isUserConfirmed)
            {
                this._loadingStart()
                debugger
                //console.log('Start Code Confirmation');
                await this.ConfirmCode()
                //console.log('End Code Confirmation');
                // .then(user => {
                //     debugger    
                // })
                console.clear();
                debugger
            }
            else
            {
                this._loadingEnd()
                console.clear();
                //console.log('Already');
                this._showToast("User account is already verified.", { variant: 'danger',title:'Error' })
                debugger
            }
            
        },
        verifyAccount() {
            accountServices.verifyAccount(this.payload).then(resp => {
                if(!resp.error) {
                    this.$router.push({name: 'SuccessPage', params: {
                        title: 'Account Verified!',
                        subTitle: 'You have successfully updated the password',
                        icon:'far fa-check-circle'
                    }})
                }
            })
        }
    }
}